import * as React from 'react';
import { ReportExtended } from '@apollo-red/hooks';
import DashboardThumbnailImage from './DashboardThumbnailImage';
import LegacyDashboardStaticThumbnailImage from './LegacyDashboardStaticThumbnailImage';
import { AuthenticationThumbnailError, NotFoundThumbnailError } from './error';
import FallbackThumbnailImage from './FallbackThumbnailImage';
import { useThumbnailStyles } from './styles';
import ThumbnailImageWrapper from './ThumbnailImageWrapper';
import ThumbnailLoader from './ThumbnailLoader';
import { useThumbnailImage } from './useThumbnailImage';

interface Props {
  report: ReportExtended;
  skipImage?: boolean;
}

const ThumbnailImage: React.FC<Props> = ({ report, skipImage }) => {
  const {
    authenticationTimeout,
    authenticatedWithTenant,
    handleError,
    handleLoaded,
    handleRetry,
    isError,
    isLoading,
    isRetrying,
    reportExternalThumbnailUrl,
    reportId,
    shouldUseFallbackImage,
    staticThumbnailUrl,
    thumbnailUrl,
    url,
    urlWithRefresh,
  } = useThumbnailImage({ report, skipImage });
  const classes = useThumbnailStyles();
  const Loader = <ThumbnailLoader className={classes.image} />;

  if (skipImage) {
    return <ThumbnailImageWrapper />;
  }

  if (shouldUseFallbackImage) {
    return <FallbackThumbnailImage reportId={reportId} />;
  }

  if (authenticationTimeout) {
    return <AuthenticationThumbnailError />;
  }

  if (!authenticatedWithTenant) {
    return <ThumbnailImageWrapper>{Loader}</ThumbnailImageWrapper>;
  }

  if (!url && !reportExternalThumbnailUrl) {
    if (staticThumbnailUrl) {
      return (
        <LegacyDashboardStaticThumbnailImage
          className={classes.image}
          imageUrl={staticThumbnailUrl}
          loaderComponent={Loader}
          reportId={reportId}
          onError={handleError}
          onLoad={handleLoaded}
        />
      );
    }

    if (!urlWithRefresh) {
      return <NotFoundThumbnailError />;
    } else {
      return <ThumbnailImageWrapper>{Loader}</ThumbnailImageWrapper>;
    }
  }

  return (
    <DashboardThumbnailImage
      className={classes.image}
      imageUrl={thumbnailUrl}
      isError={isError}
      isLoading={isLoading}
      isRetrying={isRetrying}
      loaderComponent={Loader}
      onError={handleError}
      onLoad={handleLoaded}
      onRetry={handleRetry}
      timeoutEnabled={
        report.isCustomReport ||
        (!report.isExternalReport && !report.isPatReport)
      }
    />
  );
};

export default ThumbnailImage;
