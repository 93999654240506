import * as React from 'react';
import { makeStyles } from '@mui/styles';
import {
  useGetUserInfo,
  useGetUserCurrentTenantReports,
  useGetUserSpecialReportById,
  useSyncUserProvisioning,
  LogReportType,
  ReportExtended,
} from '@apollo-red/hooks';
import { FullReportContextProvider } from '@components/fullReport';
import { useFeatureFlag } from '@utils/launchDarkly';
import TableauViewWithLogging from './TableauViewWithLogging';

const { setTimeout } = window;

interface TableauAppAuthContext {
  authenticatedWithTenant: boolean;
  authenticationTimeout: boolean;
  report?: ReportExtended;
}

const useStyles = makeStyles({
  dashboardContainer: {
    opacity: 0,
    position: 'fixed',
    overflow: 'hidden',
    width: '1px',
  },
});

export const TableauAuthContext = React.createContext<TableauAppAuthContext>({
  authenticatedWithTenant: false,
  authenticationTimeout: false,
});

const TableauAuthContextProvider: React.FC<{}> = ({ children }) => {
  const classes = useStyles();
  const { authReport } = useGetUserCurrentTenantReports();
  const { user } = useGetUserInfo();
  const { syncUserProvisioning } = useSyncUserProvisioning();
  const [currentTenantId, setCurrentTenantId] = React.useState('');
  const [hasReprovisionedUser, setHasReprovisionedUser] = React.useState(false);
  const [authenticatedWithTenant, setAuthenticatedWithTenant] =
    React.useState(false);
  const [authenticationTimeout, setAuthenticationTimeout] =
    React.useState(false);
  const nextTenantId = user.user_settings.default_tenant?.id ?? '';
  const timer = React.useRef(-1);
  const dashboardsLoginAuthTimeout = useFeatureFlag(
    'dashboardsLoginAuthTimeout',
  );
  const { report: authReportExtended } = useGetUserSpecialReportById(
    authReport?.id ?? '',
  );

  const AUTHENTICATION_TIMEOUT =
    dashboardsLoginAuthTimeout * 1000 ||
    (process.env.TABLEAU_AUTHENTICATION_TIMEOUT
      ? parseInt(process.env.TABLEAU_AUTHENTICATION_TIMEOUT, 10)
      : 20000);

  const handleFirstInteractive = () => {
    clearTimeout(timer.current);
    setAuthenticationTimeout(false);
    setAuthenticatedWithTenant(true);
  };

  const handleVizDispose = () => {
    setAuthenticatedWithTenant(false);
  };

  React.useEffect(() => {
    timer.current = setTimeout(() => {
      setAuthenticationTimeout(true);
    }, AUTHENTICATION_TIMEOUT);
    return () => {
      clearTimeout(timer.current);
      setAuthenticationTimeout(false);
    };
  }, [AUTHENTICATION_TIMEOUT, nextTenantId]);

  React.useEffect(() => {
    if (currentTenantId !== nextTenantId) {
      setCurrentTenantId(nextTenantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTenantId]);

  React.useEffect(() => {
    if (nextTenantId) {
      const syncUser = async () => {
        const { data } = await syncUserProvisioning(nextTenantId, user.id);
        setHasReprovisionedUser(!!data?.syncUserProvisioning);
      };
      syncUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTenantId]);

  return (
    <TableauAuthContext.Provider
      value={{
        authenticatedWithTenant:
          nextTenantId === currentTenantId && authenticatedWithTenant,
        authenticationTimeout,
      }}
    >
      {authReport && authReportExtended && (
        <div
          key={`${nextTenantId}_${hasReprovisionedUser}`}
          className={classes.dashboardContainer}
        >
          <FullReportContextProvider report={authReportExtended}>
            <TableauViewWithLogging
              url={authReport.report_tableau_full_report_url}
              handleFirstInteractive={handleFirstInteractive}
              handleVizDispose={handleVizDispose}
              logContext={{
                tenant_id: nextTenantId,
                report_id: authReport.id,
                report_type: LogReportType.Login,
              }}
            />
          </FullReportContextProvider>
        </div>
      )}
      {children}
    </TableauAuthContext.Provider>
  );
};

export default TableauAuthContextProvider;
