import * as React from 'react';
import { TableauAuthContext } from './TableauAuthContextProvider';
import {
  useGetUserCurrentUniversalFilters,
  useGetUserDefaultTenantId,
  LogReportType,
} from '@apollo-red/hooks';
import { useTableauUrlWithRefresh, useUrlAction } from '@utils/tableau';
import TableauViewWithLogging from './TableauViewWithLogging';
import { TableauViewBaseProps } from './TableauViewBase';
import { Viz } from './types';

export interface ApplyUniversalFiltersProps {
  basic?: boolean;
  geographic?: boolean;
}

export interface TableauViewProps extends TableauViewBaseProps {
  applyUniversalFilters?: ApplyUniversalFiltersProps;
  reportLogContext: {
    reportId: string;
    reportType: LogReportType;
    reportName: string;
    reportCategory: string;
    originalReportId?: string;
  };
}

const TableauView: React.FC<TableauViewProps> = ({ url, reset, ...props }) => {
  const tableauAuth = React.useContext(TableauAuthContext);
  const defaultTenantId = useGetUserDefaultTenantId();
  const { loading, universalFilters } = useGetUserCurrentUniversalFilters();
  const viewUrl = useTableauUrlWithRefresh({ url, forceRefreshReports: reset });
  const { handleVizUrlAction, disableUrlActionsPopups } = useUrlAction();

  if (!tableauAuth.authenticatedWithTenant || loading) {
    return null;
  }

  const handleFirstInteractive = (viz: Viz) => {
    handleVizUrlAction(viz);

    if (props.handleFirstInteractive) {
      props.handleFirstInteractive(viz);
    }
  };

  const {
    currency,
    unit_of_measurement,
    countries,
    states,
    cities,
    properties,
  } = universalFilters;

  const basic = {
    p_area: unit_of_measurement ? [unit_of_measurement] : [],
    p_currency: currency ? [currency] : [],
  };

  const geographic = {
    Country: countries.filters.map(f => f.filter_value),
    'State/Province': states.filters.map(f => f.filter_value),
    City: cities.filters.map(f => f.filter_value),
    'Property Name': properties.filters.map(f => f.filter_value),
  };

  const { applyUniversalFilters, initialFilters: filters } = props;

  let initialFilters = applyUniversalFilters?.basic
    ? { ...basic, ...filters }
    : filters;

  initialFilters = applyUniversalFilters?.geographic
    ? { ...geographic, ...initialFilters }
    : initialFilters;

  return (
    <TableauViewWithLogging
      url={viewUrl}
      {...props}
      handleFirstInteractive={handleFirstInteractive}
      disableUrlActionsPopups={disableUrlActionsPopups}
      initialFilters={initialFilters}
      logContext={{
        tenant_id: defaultTenantId,
        report_type: props.reportLogContext.reportType,
        report_id: props.reportLogContext.reportId,
        original_report_id: props.reportLogContext.originalReportId,
      }}
    />
  );
};

export default TableauView;
