import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import ThumbnailError, { ThumbnailErrorProps } from './ThumbnailError';

type AuthenticationThumbnailErrorProps = Pick<
  ThumbnailErrorProps,
  'forFullDashboard' | 'isSidebarExpanded'
>;

const AuthenticationThumbnailError: React.FC<AuthenticationThumbnailErrorProps> =
  ({ forFullDashboard, isSidebarExpanded }) => {
    const { t } = useTranslation();

    return React.useMemo(
      () => (
        <ThumbnailError
          forFullDashboard={forFullDashboard}
          isSidebarExpanded={isSidebarExpanded}
          icon="IssueAuthentication"
          title={t('tableauAuthenticationIssueTitle')}
          message={
            <Trans i18nKey={'tableauAuthenticationIssue'}>
              {t('tableauAuthenticationIssue')}
            </Trans>
          }
        />
      ),
      [forFullDashboard, isSidebarExpanded, t],
    );
  };

export default AuthenticationThumbnailError;
